import React from 'react'
import styles from './HomePlayer.module.scss'
import PlayIcon from '../icons/PlayIcon'
import Lightbox from '../Lightbox/Lightbox'

export default function HomePlayer() {
  return (
    <Lightbox
      id="home player"
      content={
        <div className={styles.contentWrapper}>
          <div className={styles.videoWrapper}>
            <div
              style={{
                position: 'relative',
                paddingTop: '56.25%'
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/d5YHY8VME6Q"
                title="Classical music"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  backgroundColor: '#080808'
                }}
              ></iframe>
            </div>
          </div>
        </div>
      }
    >
      <div className={styles.iconWrapper}>
        <PlayIcon />
      </div>
    </Lightbox>
  )
}
