import React from 'react'
import { graphql } from 'gatsby'

import HomePlayer from '../components/HomePlayer/HomePlayer'
import BGImg from 'gatsby-background-image'
import SEO from '../components/SEO/SEO'

export default function IndexPage(props) {
  const {
    markdownRemark: {
      frontmatter: {
        image: {
          childImageSharp: { fluid }
        }
      }
    }
  } = props.data
  return (
    <>
      <SEO></SEO>
      <BGImg
        fluid={fluid}
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <HomePlayer />
      </BGImg>
    </>
  )
}

export const indexPageQuery = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/content/pages/home.md/" }) {
      frontmatter {
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
      }
    }
  }
`
